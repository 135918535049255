import React from 'react';
import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthListener from '../hooks/use-auth-listener';
import Fallback from '../components/loaders/Fallback-animation';

export default function IsUserLoggedIn({
    memberPath,
    affiliatePath,
    adminPath,
    defaultPath,
}) {
    const { user, permissions, isLoading } = useAuthListener();


    if (isLoading) {
        return <Fallback />;
    }


    if (!user) {
        // Usuario no autenticado, mostrar rutas públicas
        return <Outlet />;
    }

    if (permissions.includes('admin')) {
        return <Navigate to={adminPath} replace />;
    } else if (permissions.includes('member')) {
        return <Navigate to={memberPath} replace />;
    } else if (permissions.includes('afiliado')) {
        return <Navigate to={affiliatePath} replace />;
    } else {
        return <Navigate to={defaultPath} replace />;
    }
}

IsUserLoggedIn.propTypes = {
    memberPath: PropTypes.string.isRequired,
    affiliatePath: PropTypes.string.isRequired,
    adminPath: PropTypes.string.isRequired,
    defaultPath: PropTypes.string.isRequired,
};
