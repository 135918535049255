import React from 'react'
import Lottie from 'lottie-react';
import FallbackAnimation from './lottie-files/FallbackAnimation.json'

function Fallback() {
    return (
        <div className='bg-black-background flex flex-col items-center justify-center w-full h-screen'>
            <Lottie
                animationData={FallbackAnimation}
                style={{
                    width: '250px',
                    color: 'white'
                }}
            />
        </div>
    )
}

export default Fallback