import React, {
  lazy,
  memo,
  Suspense,
} from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import UserContext from './context/user'
import useAuthListener from './hooks/use-auth-listener'
import * as ROUTES from './constants/routes'
import Fallback from './components/loaders/Fallback-animation.js'
import IsUserLoggedIn from './helpers/isUserLoggedIn'
import ProtectedRoutes from './helpers/ProtectedRoutes.jsx'

// LANDING
const Landing = lazy(() => import('./pages/landing/landing.js'))
// USERS DASHBOARD 
const Dashboard = lazy(() => import('./pages/dashboard.js'))
// LIVE MATCHES
const FootballLive = lazy(() => import('./pages/live.js'))
// MATCHES PREDICTIONS
const FixtureDetails = lazy(() => import('./pages/prediction.js'))
// MATCH SCHEDULE
const Rounds = lazy(() => import('./pages/rounds.js'))
// 404 - NOT FOUND
const NotFound = lazy(() => import('./pages/not-found'))

// AUTH
const Login = lazy(() => import('./pages/login'))
const SignUp = lazy(() => import('./pages/signup'))
const Patner = lazy(() => import('./pages/auth/afiliate'));
const ReferralLink = lazy(() => import('./pages/auth/signup/'))
const ForgotPassword = lazy(() => import('./pages/auth/reset-password'));

// USER SETTINGS
const Settings = lazy(() => import('./pages/settings'))
// USER PROFILE
const Profile = lazy(() => import('./pages/profile/user-profile.js'))
// BUY CREDITS
const Credits = lazy(() => import('./pages/buy-credits/buy-credits.js'))

// - ADMIN DASHBOARD
const AdminDashboard = lazy(() => import('./pages/panel-admin/panel-admin'))

// - PANEL de Afiliados 
const DashboardAfiliados = lazy(() => import('./pages/afiliados'))

// OTHERS
const Leagues = lazy(() => import('./pages/leagues.js'))
const Lineups = lazy(() => import('./pages/lineup.js'))

// - Policy
const Policy = lazy(() => import('./pages/policy/policy'))
const Terms = lazy(() => import('./pages/policy/terms'))
const Contact = lazy(() => import('./pages/marketing/contact'))

// - Stripe
const Success = lazy(() => import('./components/loaders/stripe-purchase-succes.js'))
const Cancel = lazy(() => import('./components/loaders/stripe-purchase-cancel.js'))


function App() {

  const { user, permissions } = useAuthListener()

  return (
    <UserContext.Provider value={{ user }}>
      <BrowserRouter>
        <Suspense fallback={<Fallback />}>
          <Routes>



            <Route
              path={ROUTES.AFILIATE}
              element={
                <ProtectedRoutes
                  redirectTo={ROUTES.DASHBOARD}
                  isAllowed={
                    !!user && permissions.includes('afiliado')}>
                  <DashboardAfiliados />
                </ProtectedRoutes>
              } >
            </Route>


            <Route
              element={
                <ProtectedRoutes
                  redirectTo={ROUTES.DASHBOARD}
                  isAllowed={!!user && permissions.includes('admin')}
                />} >
              <Route path={ROUTES.ADMIN_DASHBOARD} element={<AdminDashboard />} exact />
              <Route path={ROUTES.AFILIATE} element={<DashboardAfiliados />} exact />
            </Route>

            <Route path={ROUTES.FOOTBALL_ROUNDS} element={<Rounds />} exact />

            {/* USUARIOS AUTENTICADOS */}
            <Route element={
              <ProtectedRoutes
                isAllowed={!!user && permissions.includes('member')}
                redirectTo={ROUTES.LANDING}
              />}>
              <Route path={ROUTES.DASHBOARD} element={<Dashboard />} exact />
              <Route path={ROUTES.SETTINGS} element={<Settings />} exact />

              <Route path={ROUTES.FOOTBALL_PREDICTION} element={<FixtureDetails />} exact />
              <Route path={ROUTES.FOOTBALL_TEAMS} element={<Leagues />} exact />
              <Route path={ROUTES.FOOTBALL_LINEUP} element={<Lineups />} exact />
              <Route path={`${ROUTES.CREDITS}`} element={<Credits />} exact />
            </Route>
            <Route path={`${ROUTES.PROFILE}:username`} element={<Profile />} exact />

            {/* USUARIOS NO AUTENTICADOS */}
            <Route element={<IsUserLoggedIn
              memberPath={ROUTES.DASHBOARD}
              affiliatePath={ROUTES.AFILIATE} // Ruta para afiliados
              adminPath={ROUTES.ADMIN_DASHBOARD} // Ruta para administradores
              defaultPath={ROUTES.LANDING}
            />}>
              <Route index element={<Landing />} />
              <Route path={ROUTES.LANDING} element={<Landing />} />
              <Route path={ROUTES.LOGIN} element={<Login />} />
              <Route path={ROUTES.RECOVER_PASSWORD} element={<ForgotPassword />} />
              <Route path={ROUTES.SIGNUP} element={<SignUp />} />
              <Route path={ROUTES.PATNERS} element={<Patner />} />
              <Route path={ROUTES.REFERRAL_SIGN_UP} element={<ReferralLink />} />
            </Route>

            {/* ODDSPEDIA WIDGET */}
            <Route path={ROUTES.FOOTBALL_LIVE} element={<FootballLive />} exact />

            {/* LAWS INFORMATION */}
            <Route path={ROUTES.POLICY} element={<Policy />} exact />
            <Route path={ROUTES.CONTACT} element={<Contact />} exact />
            <Route path={ROUTES.TERMS} element={<Terms />} exact />

            {/* PAYMENTS STATUS PAGES */}
            <Route path={ROUTES.SUCCESS} element={<Success />} exact />
            <Route path={ROUTES.CANCEL} element={<Cancel />} exact />

            <Route path="*" element={<NotFound />} />

          </Routes>
        </Suspense>
      </BrowserRouter>
    </UserContext.Provider>
  );
}


export default memo(App);