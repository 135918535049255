// LANDING
export const LANDING = '/' 
// DASHBOARD
export const DASHBOARD = '/home' 
export const SETTINGS ='/settings'
export const PROFILE ='/me/'
// COMPRA DE CREDITOS
export const CREDITS ='/credits/purchase-credits/'
// PREDICCIONES DEPORTIVAS
export const FOOTBALL_PREDICTION = '/soccer/fixture-details/:fixtureID' 
// PARTIDOS 
export const FOOTBALL_ROUNDS ='/football/rounds'
// AUTENTICACIÓN
export const LOGIN = '/login'
export const SIGNUP = '/sign-up'
export const PATNERS = '/register/afiliados/';
export const REFERRAL_SIGN_UP = '/sign-up/:referralCode';
// RECUPERAR CONTRASEÑA
export const RECOVER_PASSWORD = '/reset-password';
// Dashboard ADMINSITRADORES
export const ADMIN_DASHBOARD = '/panel/admin-dashboard';
//OTROS
export const FOOTBALL_TEAMS ='/football/leagues'
export const FOOTBALL_LINEUP ='/football/lineup'
// AFILIADOS
export const AFILIATE = '/backoffice/afiliado/';
export const NOTFOUND = '/404';
// Policy
export const POLICY = '/policy';
export const TERMS = '/terms';

// Contacto
export const CONTACT = '/contacto';
export const SUCCESS = '/success';
export const CANCEL = '/cancel';

/* ODDSPEDIA WIDGETS*/
// PARTIDOS EN VIVO
export const FOOTBALL_LIVE = '/livescore' 