import { useState, useEffect, useContext } from 'react';
import FirebaseContext from '../context/firebase'
import { getFirestore, doc, getDoc } from "firebase/firestore";

export default function useAuthListener() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('authUser')));
    const [permissions, setPermissions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { firebase } = useContext(FirebaseContext);


    useEffect(() => {
        const listener = firebase.auth().onAuthStateChanged(async (authUser) => {
            if (authUser) {

                try {
                    const db = getFirestore(firebase);
                    const userRef = doc(db, 'users', authUser.uid);
                    getDoc(userRef).then((docSnapshot) => {
                        if (docSnapshot.exists()) {
                            const userData = docSnapshot.data();
                            const storedPermissions = userData?.permissions || [];

                            localStorage.setItem('authUser', JSON.stringify(authUser));
                            setUser(authUser);
                            setPermissions(storedPermissions);
                            setIsLoading(false);
                        }
                    });
                } catch (error) {
                    console.error("Error fetching user data:", error);
                } finally {
                }
            } else {
                // we don't have an authUser: threrfore clear the localStorage
                localStorage.removeItem('authUser');
                setUser(null);
                setPermissions([]);
                setIsLoading(false);
            }

        });
        return () => listener();
    }, [firebase])


    return { user, permissions, isLoading }
}